import React from 'react';
import {Layout} from "@layout/Layout/Layout";
import {Link} from 'gatsby'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import Raionebi from '@elegantstack/gatsby-starter-flexiblog-agency/src/components/Raionebi';

const Navigator = (props) => {
    return (
        <Layout {...props}>
            <Seo title='ბიზნეს ინფო ქალაქების მიხედვით' description='იპოვეთ ბიზნეს მისამართები ქალაქების მიხედვით' />
            {/* <Divider /> */}
            <Raionebi/>
        </Layout>
    );
};

export default Navigator;